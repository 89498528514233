<template>
	<div class="programm">
        <div v-if="loading">
            <svg width="1em" height="1em" viewBox="0 0 20 20" focusable="false" role="img" alt="icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-spin ml-1 bi-arrow-clockwise bi-spin ml-1 b-icon bi" style="font-size: 300%;"><g><path fill-rule="evenodd" d="M10 4.5a5.5 5.5 0 105.5 5.5.5.5 0 011 0 6.5 6.5 0 11-3.25-5.63l-.5.865A5.472 5.472 0 0010 4.5z" clip-rule="evenodd"></path><path fill-rule="evenodd" d="M10.646 1.646a.5.5 0 01.708 0l2.5 2.5a.5.5 0 010 .708l-2.5 2.5a.5.5 0 01-.708-.708L12.793 4.5l-2.147-2.146a.5.5 0 010-.708z" clip-rule="evenodd"></path></g></svg>
        </div>
        <div v-else-if="error" style="color:red; padding-bottom:30px;">{{ error }}<br><br>Bitte laden Sie diese Seite zu einem späteren Zeitpunkt noch einmal.</div>
        <div v-else-if="programm">
            <div class="room-chooser">
                <a v-for="(room, rIndex) in programm.rooms" :key="'room-'+rIndex" :title="room.name" href="#" @click.prevent="roomIndex=rIndex" :class="roomIndex===rIndex?'active':''">{{ room.name }}</a>
            </div>

            <table v-for="(room, rIndex) in programm.rooms" :key="'room-'+rIndex" :title="room.name" class="room" v-show="roomIndex===rIndex">
                <thead>
                    <tr>
                        <th>Zeit</th>
                        <th>Veranstaltung</th>
                        <th>Sprecher</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(event, eventIndex) in room.events" :key="'event-'+eventIndex" :class="'event'+(event.persons?' with-persons':' without-persons')">
                        <td class="time">
                            {{ event.timeFrom }}-{{ event.timeTo }} Uhr
                        </td>
                        <td>
                            <div class="title">{{ event.title }}</div>
                            <div class="description" v-html="event.description"></div>
                        </td>
                        <td v-show="event.persons">
                            <div v-for="(person, personIndex) in event.persons" :key="'person-'+personIndex" class="person">
                                <div class="name">{{person.name}}</div>
                                <div class="description" v-html="person.description"></div>
                                <div v-if="person.image" class="image" :style="'background-image: url(\''+person.image+'\')'"></div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
	</div>
</template>

<script>


import assetsProgramm from '@/assets/programm.json'
import axios from 'axios';

export default {
	name: 'webComponent',
	components:{},
    props:{
        file: {
            type: String,
            default: "https://neu.duesseldorfer-diabetestag.de/wp-content/plugins/ddt-admin/api/getTimetable"
        }
    },
	data: function () {
        
		return {
            axios,
            url:this.file,
            assetsProgramm,
            loading:true,
            testProgressAnimation:0, // ms | false | 0
            error:false,
			programm:false,
			roomIndex:0
		}
	},
    async created(){
        this.programm = await this.load();
        this.loading = false;
    },
    methods:{
        async load(){

            // if( this.url.indexOf('localhost.') > -1){

            //     (async () => {
            //         if( this.testProgressAnimation )
            //             await new Promise((res) => setTimeout(res, this.testProgressAnimation));
            //         resolve(this.assetsProgramm);
            //     })();
            //     return true;
            // }
            
            var response;
            try {
                response = await axios.post(this.url);
                
            } catch (error) {
                response = false;
                console.log(error);
            }

            console.log("response", response);
            return new Promise((resolve, reject)=>{
                if( ! response || typeof response.data == 'undefined' )
                {
                    this.error = "Die Datendatei enthält kein gültiges JSON-Format.";
                    reject();
                }

                (async () => {
                    if( this.testProgressAnimation )
                        await new Promise((res) => setTimeout(res, this.testProgressAnimation));
                    resolve(response.data);
                })();
            
                return true;
            });
        },
    }
}
</script>

<style type="text/scss">
.bi-spin {
  -webkit-animation: bi-spin 2s infinite linear;
  animation: bi-spin 2s infinite linear;
}
.bi-pulse {
  -webkit-animation: bi-spin 1s infinite steps(8);
  animation: bi-spin 1s infinite steps(8);
}
@-webkit-keyframes bi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes bi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
</style>

<style>

.programm .room-chooser{
	margin-bottom:25px;
}

.programm .room-chooser a{
	display:inline-block;
	color:black;
	background-color: #cfd5cb;
	padding:15px 25px;
	text-decoration: none;
	font-weight:bold;
}
.programm .room-chooser a.active{
	font-weight:bold;
	color:black;
	background-color: #b1c5a4;
}
.programm .room-chooser a:not(:first-child)
{
    margin-left:20px;
}

.programm{
	font-family: Arial, Helvetica, sans-serif;

    text-align:center;
}

.programm table 
{
	width:100%;
	max-width:1000px;
	border-collapse: collapse;
    margin-left:auto;
    margin-right:auto;
}

.programm table th{
	text-align: left;
}

.programm table th,
.programm table td{
	padding:25px;
}

.programm table td{
	text-align: left;
	vertical-align: top;
	width:auto;
}

.programm table td:first-child{
	white-space: nowrap;
	width:1px;
}
.programm table td:last-child{
	width:260px;
}

.programm .event > td{
	border-top:1px solid #ddd;
}
.programm .event:last-child > td{
	border-bottom:1px solid #ddd;
}

.programm .event.with-persons td{
	background-color: #e9ece6;
}


.programm .event .time{
	font-weight: bold;
	color:#45a406;
    font-size:1.2em;
}

.programm .event .title{
	font-weight: bold;
    font-size:1.2em;
}

.programm .event .person:not(:last-child){
	margin-bottom:25px;
}

.programm .event .person .name{
	font-weight: bold;
}
.programm .event .person .description{
	font-size:0.85em;
}

.programm .event .person .image{
	margin-top:10px;
	width:120px;
	height:120px;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	border-radius:50%;
}


@media (width <= 1000px) {

    .programm .room-chooser a:not(:first-child)
    {
        margin-left:0px;
    }

    table{
        display:block;
    }

    th{
        display:none;
    }
    td{
        display:block;
        width:unset !important;
        border:none !important;
        padding:25px !important;
    }
    td.time{
        padding-bottom:0px !important;
    }

}


</style>